import React from 'react';
import { Hero, SEO, EstimatorApp, Link } from 'components';
import styled from 'styled-components';
import { useWindowSize } from 'hooks';
import { graphql } from 'gatsby';

const MediaEstimator = ({ data, location }) => {
  const { windowWidth } = useWindowSize();
  const { allHealthcarePublications } = data;
  return (
    <>
      <SEO
        pageSpecificTitle="Media Estimator | Agency X"
        pageSpecificDescription="Use this handy tool to estimate media costs and plan your campaigns with ease!"
      />
      <Hero>
        <HeroContent>
          <h1>
            AX Media
            <br />
            Estimator
          </h1>
          <div>
            <p>Use this handy tool to estimate media costs and plan your campaigns with ease!</p>
            {windowWidth > 1100 && (
              <Link href="estimator-app" offset={-77} style={{ marginTop: '1.05rem' }}>
                Let&apos;s Go!
              </Link>
            )}
          </div>
        </HeroContent>
      </Hero>
      <EstimatorApp publications={allHealthcarePublications.edges} />
    </>
  );
};

const HeroContent = styled.div`
  div {
    display: flex;
    flex-direction: column;
  }

  p {
    max-width: 23em;
    margin: 1.45em 0 1.75em;
  }

  @media screen and (min-width: 48rem) {
    h1 {
      position: relative;
      right: 2px;
    }

    p {
      margin: 1.675em 0 3.45em;
    }
  }

  @media screen and (min-width: 68.75rem) {
    display: flex;
    justify-content: flex-start;
    margin: 0 auto 7.2rem;
    max-width: 1180px;

    h1 {
      display: inline-block;
      flex-shrink: 0;
    }

    div {
      margin-left: 11rem;
    }

    p {
      margin: 1.675em 0 2.15em;
    }
  }
`;

export const MediaEstimatorQuery = graphql`
  query {
    allHealthcarePublications {
      edges {
        node {
          hcpOnly
          nameOfPublication
          mediaType
          associatedBody
          publisher
          print
          contact
          website
          email
          contentDescription
          frequency
          audience
          reach
          importantInfo
          deadlines
          agencyRebate
          maximumPotentialDiscount
          frontCover
          backCover
          doublePageSpread
          fullPage
          halfPage
          quarterPage
          insideFront
          _10X4
          sponsorshipStrip
          looseInserts_WrapArounds_BoundInserts
          cpdModuleSponsorship
          _1MoMpu
          _3MoMpu
          _6MoMpu
          _12MoMpu
          _1MoLeaderboard
          _3MoLeaderboard
          _6MoLeaderboard
          _12MoLeaderboard
          mpu_lbdForDigitalIssue
          bespokeEmail__under1000Recipients_
          bespokeEmail__over1000Recipients_
          tramWrap
          tramTop
          tramSide
          tramCentre
          dominationSkyline
          portraitPanels
          straplines
          backPageSolus
          frontPageSolus
          perCycle
          _30SecondSpot
          billboard_HpCpm
          lbd_MpuCpm
          _1DayHomepageTakeover
          other
        }
      }
    }
  }
`;

export default MediaEstimator;
